<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">Opal</h2>
        </b-link>
        <b-card-title class="mb-1 text-center">
          Welcome to Opal! 👋
        </b-card-title>
        <b-card-text class="mb-2 text-center"> </b-card-text>
      </b-card>

      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { BCard, BLink, BCardTitle, BCardText, BButton } from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { retrieveUserAccess } from '@/router/middlewares'
import store from '@/store'
import router from '@/router'

export default {
  components: {
    // BSV
    BCard,
    BButton,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText
  },
  /**
   * Remove all kind of Authorization and Store Content
   */
  mounted() {
    // login user with method and then redirect to dashboard
    this.test()
  },
  methods: {
    test() {
      if (store.state.auth.user !== null) {
        // async store dispatch for 20 seconds
        setTimeout(() => {
          store.dispatch('auth/setIsLoggedIn', true)
        }, 1000)
        // redirect user to where they were before
        router.push({
          name: 'dashboard'
        })
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
